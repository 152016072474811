export const MobileMenu = () => {
    const path = window.location.pathname;
  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close">
          <i className="icon-close"></i>
        </span>
        <nav className="mobile-nav">
          <ul className="mobile-menu">
            <li className={`${path === '/'? 'active': null}`}>
              <a href="/">Home</a>
            </li>
            <li className={`${path === '/shop'? 'active': null}`}>
              <a href="/shop">Shop</a>
            </li>
            <li className={`${path === '/about'? 'active': null}`}>
              <a href="/about">About Us</a>
            </li>
            <li className={`${path === '/contact'? 'active': null}`}>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </nav>

        <div className="social-icons">
          <a href="#/" className="social-icon" target="_blank" title="Facebook">
            <i className="icon-facebook-f"></i>
          </a>
          <a href="#/" className="social-icon" target="_blank" title="Twitter">
            <i className="icon-twitter"></i>
          </a>
          <a href="#/" className="social-icon" target="_blank" title="Instagram">
            <i className="icon-instagram"></i>
          </a>
          <a href="#/" className="social-icon" target="_blank" title="Youtube">
            <i className="icon-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  );
};
