export const Banner = () => {
  return (
    <div className="intro-slider-container mb-0">
      <div
        className="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
        data-toggle="owl"
        data-owl-options='{"nav": false, "dots": false}'
      >
        <div
          className="intro-slide"
          style={{
            backgroundImage:
              "url(assets/trinity-images/tetron36.png)",
          }}
        >
          <div className="container intro-content text-center">
            <h3 className="intro-subtitle text-white">Welcome to </h3>
            <h1 className="intro-title text-white">Trinity Shop</h1>
            <div className="intro-text text-white">Best Fabric</div>
            {/* <a href="/shop" className="btn btn-primary">
              Shop NOW
            </a> */}
          </div>
        </div>

        <div
          className="intro-slide"
          style={{
            backgroundImage:
              "url(assets/trinity-images/nylon.png)",
          }}
        >
          <div className="container intro-content text-center">
            <h3 className="intro-subtitle text-white">Best prices at</h3>
            <h1 className="intro-title text-white">Trinity Shop</h1>
            {/* <div className="intro-text text-white"></div> */}
            <a href="/shop" className="btn btn-primary">
              Shop NOW
            </a>
          </div>
        </div>
      </div>

      <span className="slider-loader text-white"></span>
    </div>
  );
};
