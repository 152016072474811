import { subDays, subHours } from "date-fns";

const now = new Date();

class ProductsApi {
  getProducts() {
    const products = [
      {
        id: "5ece2c077e39da27658aa8a9",
        attributes: ["#e5dcb1", "#b9cbd8"],
        category: "Clothing",
        createdAt: subDays(now, 1).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/tetron36-single.png",
        hoverImage: "/assets/trinity-images/tetron36.png",
        inStock: true,
        isAvailable: true,
        isShippable: false,
        name: "Tetron 36",
        slug: "this is a popular fabric used as a uniform clothing and different fabric linings.",
        description:
          "this is a popular fabric used as a uniform clothing and different fabric linings. Its available in a variety of colours to suite the different colour needs of different customers. It’s packed in bundles and rolls in sizes of 36-inch, 45 inch and 60-inch width and 30 yards length.",
        price: 30000,
        quantity: 85,
        sku: "401_1BBXBK",
        status: "published",
        updatedAt: subHours(now, 6).getTime(),
        variants: 2,
      },
      {
        id: "5ece2c0d16f70bff2cf86cd8",
        category: "Clothing",
        attributes: ["#e5dcb1", "#b9cbd8"],
        createdAt: subDays(now, 3).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/gentleman.png",
        hoverImage: "/assets/trinity-images/gentleman2.png",
        images: ["/assets/trinity-images/gentleman2.png"],
        inStock: false,
        isAvailable: false,
        isShippable: true,
        name: "Gentleman",
        price: 95.0,
        quantity: 0,
        sku: "978_UBFGJC",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 2).getTime(),
        variants: 1,
        slug: "this is a fabric popular for making corporate uniforms of different institutions and functions",
        description:
          "this is a fabric popular for making corporate uniforms of different institutions and functions. It has a soft hand feel and available in different colours, its available in 57-inch width and 30 yards.",
      },
      {
        id: "5ece2c123fad30cbbff8d060",
        attributes: ["#9fac76", "#333333"],
        category: "Clothing",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/stiff-net.png",
        hoverImage: "/assets/trinity-images/stiff-net.jpeg",
        inStock: true,
        isAvailable: true,
        isShippable: false,
        name: "Stiff net",
        price: 70000,
        quantity: 48,
        sku: "211_QFEXJO",
        status: "draft",
        updatedAt: subDays(subHours(now, 2), 1).getTime(),
        variants: 5,
        slug: "this is a hardened net fabric mainly used as interlining with different fabrics for different functions",
        description:
          "this is a hardened net fabric mainly used as interlining with different fabrics for different functions, its mostly available in pure white and black colours but other colours are available on special request. It’s packed in bundles of 25 yards length and 72-inch width.",
      },
      {
        id: "5ece2c123fad30cbbee8d261",
        // attributes: ["#9fac76", "#333333"],
        category: "Clothing",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/stiff_paper.jpeg",
        hoverImage: "/assets/trinity-images/stiff-paper.jpeg",
        inStock: true,
        isAvailable: true,
        isShippable: false,
        name: "Stiff Paper",
        price: 70000,
        quantity: 48,
        sku: "211_QFEXJO",
        status: "draft",
        updatedAt: subDays(subHours(now, 2), 1).getTime(),
        variants: 5,
        slug: "this is a white non-woven fabric used in different fabrics to harden them and suitable for designing, its mainly used in embroidery works. Its available in 25 yards roll with 40-inch widths",
        description:
          "this is a white non-woven fabric used in different fabrics to harden them and suitable for designing, its mainly used in embroidery works. Its available in 25 yards roll with 40-inch width",
      },
      {
        id: "5ece2c1be7996d1549d94e34",
        category: "Accessories",
        createdAt: subDays(now, 12).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/bag-zippers.png",
        hoverImage: "/assets/trinity-images/bag-zip.jpeg",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Bag zippers",
        price: 40000,
        quantity: 5,
        sku: "321_UWEAJT",
        status: "published",
        updatedAt: subDays(subHours(now, 7), 1).getTime(),
        variants: 1,
        slug: "",
        description: "",
      },
      {
        id: "b393ce1b09c1254c3a92c827",
        attributes: ["#f48a5b", "#333333"],
        category: "Clothing",
        createdAt: subDays(now, 4).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/nylon.png",
        hoverImage: "/assets/trinity-images/nylon.jpeg",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Nylon",
        price: 70000,
        quantity: 10,
        sku: "592_LDKDI",
        status: "draft",
        updatedAt: subDays(subHours(now, 1), 1).getTime(),
        variants: 1,
        slug: "this is a 100% nylon fabric packed in rolls of 50 yards and 60-inch width used as fabric lining and a decoration fabric on different events",
        description:
          "this is a 100% nylon fabric packed in rolls of 50 yards and 60-inch width used as fabric lining and a decoration fabric on different events. It has a variety of other uses and its available in variety of colours to suite the different needs.",
      },
      {
        id: "a6ede15670da63f49f752c89",
        category: "Accessories",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/corns.png",
        hoverImage: "/assets/trinity-images/corn1.jpeg",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Sewing threads",
        price: 76.99,
        quantity: 22,
        sku: "324_DKSEKD",
        status: "draft",
        updatedAt: subDays(subHours(now, 3), 3).getTime(),
        variants: 1,
        slug: "these are 100% polyester sewing threads rolled on cones of different sizes to fit different tailoring needs.",
        description:
          "these are 100% polyester sewing threads rolled on cones of different sizes to fit different tailoring needs. They are available for ordinary sewing works (40s/2) and embroidery sewing (120d/2). A variety of colours are available to suit the different tailoring needs of different fabrics.",
      },

      {
        id: "bcad5524fe3a2f8f8620ceda",
        category: "Accessories",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/bracup.jpeg",
        hoverImage: "/assets/trinity-images/bra-cups.jpeg",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Bra cups",
        price: 50000,
        quantity: 15,
        sku: "598_DOKEII",
        status: "published",
        updatedAt: subDays(subHours(now, 5), 6).getTime(),
        variants: 1,
        slug: "these are available in different colours and designs to suite different tailoring needs.",
        description:
          "these are available in different colours and designs to suite different tailoring needs. They are mainly used in sewing ladies’ dresses.",
      },
      {
        id: "bcad6624fe3b2f8f9620ceda",
        category: "Clothing",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/kapere_1.png",
        hoverImage: "/assets/trinity-images/kapere_1.png",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Checked Fabric",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "this is a school uniform fabric available in a variety of colours like red, brown, blue, green and others. It’s packed in rolls of 30 yards and 45-inch width",
        description:
          "this is a school uniform fabric available in a variety of colours like red, brown, blue, green and others. It’s packed in rolls of 30 yards and 45-inch width",
      },
      {
        id: "bcad6624fe3b2f8f9620ceda",
        category: "Clothing",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/stripe.png",
        hoverImage: "/assets/trinity-images/stripes.jpeg",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Stripe",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "this a fabric packed in 45-inch width rolls used mainly for unforms and other clothing needs.",
        description:
          "this a fabric packed in 45-inch width rolls used mainly for unforms and other clothing needs. Its available in a variety of colours for different tastes and preference. It’s a high quality and long-lasting material.",
      },
      {
        id: "bcad6624ne3b2f8f9620cedax",
        category: "Accessories",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/hook_loop.png",
        hoverImage: "/assets/trinity-images/hook_loop.png",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Elastic Tape",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "this is an easy fastening fabric ready to use and available in mainly black colour but different sizes",
        description:
          "this is an easy fastening fabric ready to use and available in mainly black colour but different sizes",
      },

      {
        id: "fcad6624ne3b2e8a9738cedz",
        category: "Accessories",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/zizzag.png",
        hoverImage: "/assets/trinity-images/zizzag.png",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Zig zag",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "this is ready to use and available in different colors",
        description: "this is ready to use and available in different colors",
      },
      {
        id: "fcad6624ne3b2e8a9038ced1",
        category: "Clothing",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/fabric.png",
        hoverImage: "/assets/trinity-images/fabric_2.png",
        images: ["/assets/trinity-images/fabric_3.png", "/assets/trinity-images/fabric_1.png"],
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Polyster Fabric",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "these are used for mens' pants; they are available in black colour mainly.",
        description:
          "these are used for mens' pants; they are available in black colour mainly.",
      },
      {
        id: "fcad3615ne3b2e4a9738cedu",
        category: "Accessories",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/zippers.jpeg",
        hoverImage: "/assets/trinity-images/zippers.jpeg",
        images: ["/assets/trinity-images/rainbowzips.jpg","/assets/trinity-images/red-zip.jpg"],
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Zippers",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "just like its name, it has different colours across its length to suite different fabric colours. It comes with different zipper heads to suite the different needs and designs. Its available in 8-inch, 24-inch lengths and a variety of colours",
        description:
          "just like its name, it has different colours across its length to suite different fabric colours. It comes with different zipper heads to suite the different needs and designs. Its available in 8-inch, 24-inch lengths and a variety of colours",
        additionalInfo: { __html: `
        <div>
        <br />
          <p>
          <span className="page-title">Rainbow zipper:</span> just like its name, it has different colours across its length to suite different fabric colours. It comes with different zipper heads to suite the different needs and designs. Its available in 8-inch, 24-inch lengths and a variety of colours
          </p>
          <br />
          <p>
          <span className="page-title">Golden zipper:</span> this has golden coloured teeth across its full length which offers brightness when used on different fabric materials and designs. It has different zipper heads to suite the different styles and demands. Its available in 8 inch and 24-inch lengths and a variety of colours
          </p>
          <br />
          <p>
            <span className="page-title">Invisible zipper:</span> for those in need of zipper services without distorting beauty, this is the right zipper for you. Its invisible when used on the different fabrics, this is due to its small size design and compactness. Its available in 8 inch and 24-inch length and a variety of colours
          </p>
          <br />
          <p>
            <span className="page-title">Long chain zippers:</span> these are plain coloured zippers available in 100m length and different sizes of 05,07,10 to suite different tailor needs. They are mainly used in workshops of bags, shoes and other home textile goods.
          </p>
        </div>
        `,
      }
      },
      {
        id: "a6ede10630da63h40f752c8a",
        category: "Accessories",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/buttons-many.jpg",
        hoverImage: "/assets/trinity-images/buttons-many.jpg",
        images: ["/assets/trinity-images/buttons_1.jpg", "/assets/trinity-images/buttons-white.jpg"],
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Buttons",
        price: 76.99,
        quantity: 22,
        sku: "324_DKSEKD",
        status: "draft",
        updatedAt: subDays(subHours(now, 3), 3).getTime(),
        variants: 1,
        others: [""],
        slug: "a variety of buttons for different clothing are available. Coats buttons, trouser buttons, shirt buttons, magnetic buttons and a variety of colours",
        description:
          "a variety of buttons for different clothing are available. Coats buttons, trouser buttons, shirt buttons, magnetic buttons and a variety of colours",
      },
      {
        id: "e0eda10630da63h40f752c8a",
        category: "Tailoring",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/tail.jpg",
        hoverImage: "/assets/trinity-images/R28.jpg",
        // images: ["/assets/trinity-images/buttons_1.jpg", "/assets/trinity-images/buttons-white.jpg"],
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "R28",
        price: 76.99,
        quantity: 22,
        sku: "324_DKSEKD",
        status: "draft",
        updatedAt: subDays(subHours(now, 3), 3).getTime(),
        variants: 1,
        others: [""],
        slug: "for ordinary household sewing machines, different accessories are available. Shuttle hooks, needle plates, bobbin cases and others are available.",
        description:
          "for ordinary household sewing machines, different accessories are available. Shuttle hooks, needle plates, bobbin cases and others are available.",
      },
      {
        id: "e0eda10630da63h40fs52c810",
        category: "Tailoring",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/sewing-part.jpg",
        hoverImage: "/assets/trinity-images/sewing-part.jpg",
        // images: ["/assets/trinity-images/buttons_1.jpg", "/assets/trinity-images/buttons-white.jpg"],
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Sewing Parts",
        price: 76.99,
        quantity: 22,
        sku: "324_DKSEKD",
        status: "draft",
        updatedAt: subDays(subHours(now, 3), 3).getTime(),
        variants: 1,
        others: [""],
        slug: "for ordinary household sewing machines, different accessories are available. Shuttle hooks, needle plates, bobbin cases and others are available.",
        description:
          "for ordinary household sewing machines, different accessories are available. Shuttle hooks, needle plates, bobbin cases and others are available.",
      },
      {
        id: "bcad6624ne3b2e8a9720ceda",
        category: "Accessories",
        createdAt: subDays(now, 7).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/waist-tape.png",
        hoverImage: "/assets/trinity-images/waist-tape.png",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Waist tape",
        price: 15000,
        quantity: 15,
        sku: "598_DOKEIF",
        status: "published",
        updatedAt: subDays(subHours(now, 8), 9).getTime(),
        variants: 1,
        slug: "these are used for mens' pants; they are available in black colour mainly.",
        description:
          "these are used for mens' pants; they are available in black colour mainly.",
      },
      {
        id: "a6ede15670da63h49f752c8a",
        category: "Accessories",
        createdAt: subDays(now, 6).getTime(),
        currency: "ugx",
        image: "/assets/trinity-images/ribbons.png",
        hoverImage: "/assets/trinity-images/ribbons.png",
        inStock: true,
        isAvailable: false,
        isShippable: true,
        name: "Ribbons",
        price: 76.99,
        quantity: 22,
        sku: "324_DKSEKD",
        status: "draft",
        updatedAt: subDays(subHours(now, 3), 3).getTime(),
        variants: 1,
        slug: "different coloured polyester ribbons are available and in different sizes to suite different needs of the consumer. They are available in 0.5 inch, 1 inch and 1.5 inch width",
        description:
          "different coloured polyester ribbons are available and in different sizes to suite different needs of the consumer. They are available in 0.5 inch, 1 inch and 1.5 inch width",
      },
    ];

    return Promise.resolve(products);
  }
}

export const productApi = new ProductsApi();
