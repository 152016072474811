export const PageHeader = (props) => {
    const { title, page, activePage } = props;
  return (
   <>
    <div
      className="page-header text-center"
      style={{ backgroundImage: "url(assets/images/page-header-bg.jpg)" }}
    >
      <div className="container">
        <h1 className="page-title">
          {title}<span>{page}</span>
        </h1>
      </div>
    </div>
    <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
    <div class="container-fluid">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item"><a href="/shop">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">{activePage}</li>
        </ol>
    </div>
</nav>
   </>
  );
};
