export const ToolBox = ({products}) => {
  return (
    <div className="toolbox">
      <div className="toolbox-left">
        {/* add sidebar-toggle class to toggle the sidebar */}
        {/* <a href="#/" className="sidebar-toggler">
          <i className="icon-bars"></i>Filters
        </a> */}
      </div>

      <div className="toolbox-center">
        <div className="toolbox-info">
          Showing <span>{products.length}</span> Products
        </div>
      </div>

      <div className="toolbox-right">
        <div className="toolbox-sort">
          <label for="sortby">Sort by:</label>
          <div className="select-custom">
            <select name="sortby" id="sortby" className="form-control">
              <option value="popularity" selected="selected">
                Most Popular
              </option>
              <option value="rating">Most Rated</option>
              <option value="date">Date</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
