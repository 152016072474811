import { useEffect, useState } from "react";
import { ProductItem } from "../Product/ProductItem";

const tabs = ["All", "Clothing", "Accessories", "Tailoring"];

export const TrendyProducts = (props) => {
  const { products } = props;
  const [activeTab, setActiveTab] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    if (activeTab === "All") {
      setFilteredProducts(products);
      return;
    }

    const filterData = products.filter(
      (product) => product.category === activeTab
    );
    setFilteredProducts(filterData);
  }, [activeTab, products]);

  return (
    <>
      <div className="container pt-6 new-arrivals">
        <div className="heading heading-center mb-3">
          <h2 className="title">Trending Products</h2>

          <ul className="nav nav-pills justify-content-center" role="tablist">
            {tabs.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a
                  onClick={() => setActiveTab(tab)}
                  className={`nav-link ${activeTab === tab ? "active" : ""}`}
                  id={`new-${tab}-link`}
                  data-toggle="tab"
                  href={`#new-${tab}-tab`}
                  role="tab"
                  aria-controls={`new-${tab}-tab`}
                  aria-selected="true"
                >
                  {tab}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="tab-content">
          {tabs.map((tab, index) => (
            <div
            key={index}
              className={`tab-pane p-0 fade ${
                activeTab === tab ? "show active" : ""
              } `}
              id={`new-${tab}-tab`}
              role="tabpanel"
              aria-labelledby={`new-${tab}-link`}
            >
              <div className="products">
                <div className="row justify-content-center">
                  {filteredProducts.slice(0,4)?.map((product, index) => (
                    <div className="col-6 col-md-4 col-lg-3" key={index}>
                      <ProductItem product={product} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-2"></div>
    </>
  );
};
