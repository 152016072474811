export const BannerGroup = () => {
  return (
    <>
      <div className="container">
        <div className="banner-group">
          <div className="row">
            <div className="col-md-6">
              <div className="banner banner-border">
                <a href="#/">
                  <img src="assets/trinity-images/zippers.jpeg" alt="Banner" />
                </a>

                <div className="banner-content">
                  <h4 className="banner-subtitle">
                    <a href="#/">New </a>
                  </h4>
                  <h3 className="banner-title">
                    <a href="#/">
                      <span>Zippers</span>
                    </a>
                  </h3>
                  <a
                    href="/shop"
                    className="btn btn-outline-primary-2 banner-link"
                  >
                    Discover Now<i className="icon-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="banner banner-border">
                <a href="#/">
                  <img src="assets/trinity-images/buttons_1.jpg" alt="Banner" />
                </a>

                <div className="banner-content">
                  <h4 className="banner-subtitle">
                    <a href="#/">Limited time only.</a>
                  </h4>
                  <h3 className="banner-title">
                    <a href="#/">
                      <span>
                        Best Fabric
                        <br />
                        Affordable Prices
                      </span>
                    </a>
                  </h3>
                  <a
                    href="/shop"
                    className="btn btn-outline-primary-2 banner-link"
                  >
                    Shop Now<i className="icon-long-arrow-right"></i>
                  </a>
                </div>
              </div>

              {/* <div className="banner banner-border-hover">
                <a href="#/">
                  <img src="assets/trinity-images/fab1.jpg" alt="Banner" />
                </a>

                <div className="banner-content">
                  <h4 className="banner-subtitle">
                    <a href="#/">This week we love...</a>
                  </h4>
                  <h3 className="banner-title">
                    <a href="#/">
                      <span>Tailors' Dream</span>
                    </a>
                  </h3>
                  <a
                    href="/shop"
                    className="btn btn-outline-primary-2 banner-link"
                  >
                    Discover Now<i className="icon-long-arrow-right"></i>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mb-4"></div> */}
    </>
  );
};
