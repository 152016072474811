import { useForm, ValidationError } from "@formspree/react";
import { useEffect, useState } from "react";

const SuccessAlert = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (visible) {
    return (
      <div className="alert alert-success mb-2" role="alert">
        Form submitted successfully
      </div>
    );
  } else {
    return null;
  }
};

export const ContactUs = () => {
  const [state, handleSubmit] = useForm("mpzvlwap");
  const [formState, setFormState] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleFormChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const submitForm = async (data) => {
    await handleSubmit(data);
    setFormState({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <main className="main">
      <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact us
            </li>
          </ol>
        </div>
      </nav>

      <div className="container-fluid">
        <div
          className="page-header page-header-big text-center"
          style={{ backgroundImage: "url(assets/trinity-images/fab.jpg)" }}
        >
          <h1 className="page-title" style={{ color: "#000" }}>
            Contact us
            <span style={{ color: "#000" }}>Keep in touch with Us</span>
          </h1>
        </div>
      </div>

      <div className="page-content pb-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <h2 className="title mb-1">Contact Information</h2>
              <p class="mb-3">
                Visit us anytime of the day and explore more and discover the
                best fabrics and other accessories we have in store and also do
                not hesitate to call us for more information.
              </p>
              <div class="row">
                <div class="col-sm-7">
                  <div class="contact-info">
                    <h3>Location Details</h3>

                    <ul class="contact-list">
                      <li>
                        <i class="icon-map-marker"></i>
                        atalanta textile centre building, shop AB 31, Nakivubo
                        mews street opposite zibula atudde arcade next to
                        Sekaziga house.
                      </li>
                      <li>
                        <i class="icon-phone"></i>
                        <a href="tel:+256785352205">+256 785 352205</a>
                      </li>
                      <li>
                        <i class="icon-phone"></i>
                        <a href="tel:+256705823830">+256 705 823830</a>
                      </li>
                      <li>
                        <i class="icon-envelope"></i>
                        <a href="mailto:e124ng@gmail.com">e124ng@gmail.com</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-5">
                  <div class="contact-info">
                    <h3>Working hours</h3>

                    <ul class="contact-list">
                      <li>
                        <i class="icon-clock-o"></i>
                        <span class="text-dark">Monday-Saturday</span> <br />
                        8am-6:30pm (East African time)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <h2 className="title">Got Any Questions?</h2>
              <p class="mb-2">Use the form below to get in touch with us</p>

              <form onSubmit={submitForm} class="contact-form mb-3">
              {state.succeeded && <SuccessAlert />}
                <div class="row">
                  <div class="col-sm-6">
                    <label for="cname" class="sr-only">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Name *"
                      name="name"
                      value={formState.name}
                      onChange={handleFormChange}
                      required
                    />
                    <ValidationError
                      prefix="Name"
                      field="name"
                      errors={state.errors}
                    />
                  </div>

                  <div class="col-sm-6">
                    <label for="cemail" class="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Email *"
                      value={formState.email}
                      onChange={handleFormChange}
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <label for="cphone" class="sr-only">
                      Phone
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      id="phone"
                      name="phone"
                      value={formState.phone}
                      onChange={handleFormChange}
                      placeholder="Phone"
                    />
                    <ValidationError
                      prefix="Phone"
                      field="phone"
                      errors={state.errors}
                    />
                  </div>

                  <div class="col-sm-6">
                    <label for="csubject" class="sr-only">
                      Subject
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      value={formState.subject}
                      onChange={handleFormChange}
                    />
                  </div>
                  <ValidationError
                    prefix="Subject"
                    field="subject"
                    errors={state.errors}
                  />
                </div>

                <label for="cmessage" class="sr-only">
                  Message
                </label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="4"
                  id="message"
                  name="message"
                  required
                  value={formState.message}
                  onChange={handleFormChange}
                  placeholder="Message *"
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
                {!state.submitting && (
                  <button
                    type="submit"
                    class="btn btn-outline-primary-2 btn-minwidth-sm"
                  >
                    <span>SUBMIT</span>
                    <i class="icon-long-arrow-right"></i>
                  </button>
                )}
                {state.submitting && (
                  <button
                    type="submit"
                    class="btn btn-outline-primary-2 btn-minwidth-sm"
                  >
                    <span>SUBMITTING</span>
                    <i class="icon-long-arrow-right"></i>
                  </button>
                )}
              </form>
            </div>
          </div>

          <div className="mb-5" />
        </div>
      </div>
    </main>
  );
};
