export const ProductItem = (props) => {
  const { product } = props;
  return (
    <>
      <div className="product product-2">
        <figure className="product-media">
          <a href={`/product/${product.id}`}>
            <img src={product.image} alt="Product" className="product-image" />
            <img
              src={product.hoverImage}
              alt="Product"
              className="product-image-hover"
            />
          </a>
        </figure>
        <div className="product-body">
          {/* <div className="product-cat">
            <a href="#/">{product.category}</a>
          </div> */}
          <h3 className="product-title">
            <a href={`/product/${product.id}`}>{product.name}</a>
          </h3>
          {product.attributes ? (
            <div className="product-nav product-nav-dots">
              {product.attributes.map((attribute) => (
                <a
                  href="#/"
                  className=""
                  style={{ background: `${attribute}` }}
                >
                  <span className="sr-only">Color name</span>
                </a>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
