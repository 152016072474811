export const AboutUs = () => {
  return (
    <main className="main">
      <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              About us
            </li>
          </ol>
        </div>
      </nav>

      <div className="container-fluid">
        <div
          className="page-header page-header-big text-center"
          style={{ backgroundImage: "url(assets/trinity-images/fab.jpg)" }}
        >
          <h1 className="page-title" style={{ color: "#000" }}>
            About us<span style={{ color: "#000" }}>Who we are</span>
          </h1>
        </div>
      </div>

      <div className="page-content pb-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <h2 className="title">Our Vision</h2>
              <p>
                Trinity Shop envisions a world where every project is infused
                with passion, every creation a masterpiece. We aspire to be more
                than just a fabric store; we want to be the spark that ignites
                imagination and innovation. Through our carefully selected range
                of fabrics, we aim to empower artisans, designers, and hobbyists
                alike to realize their visions, one textile at a time. Join us
                on this colorful journey as we weave dreams into reality.
              </p>
            </div>

            <div className="col-lg-6">
              <h2 className="title">Our Mission</h2>
              <p>
                At Trinity Shop, our mission is to provide fabric enthusiasts
                with a vibrant canvas for their creativity. We strive to curate
                a diverse collection of materials, from luxurious silks to
                durable cottons, fostering a community where every stitch tells
                a story. With a dedication to quality and customer satisfaction,
                we aim to be the go-to destination for all your fabric needs,
                inspiring endless possibilities in design and craft.
              </p>
            </div>
          </div>

          <div className="mb-5" />
        </div>

        <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 mb-3 mb-lg-0">
                <h2 className="title">Who We Are</h2>
                <p className="lead text-primary mb-3">
                  At Trinity Shop, we are more than the sum of our threads; we
                  are the fabric of imagination, woven together by a shared love
                  for artistry and expression. Join us as we celebrate the joy
                  of creating and the beauty of craftsmanship.
                </p>
                <p className="mb-2">
                  We are the embodiment of creativity, offering more than just
                  materials—we offer the tools to turn ideas into reality.
                  Whether you're a seasoned seamstress or a novice crafter, we
                  welcome you to explore our carefully selected collection and
                  embark on a journey of limitless possibilities.
                </p>
              </div>

              <div className="col-lg-6 offset-lg-1">
                <div className="about-images">
                  <img
                    src="assets/trinity-banner.png"
                    alt=""
                    className="about-img-front"
                    width={400}
                    height={400}
                  />
                  <img
                    src="assets/trinity-banner_1.png"
                    alt=""
                    className="about-img-back"
                    width={400}
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {/* <div className="row">
                        <div className="col-lg-5">
                            <div className="brands-text">
                                <h2 className="title">The world's premium design brands in one destination.</h2>
                                <p>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nis</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="brands-display">
                                <div className="row justify-content-center">
                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/1.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/2.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/3.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/4.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/5.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/6.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/7.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/8.png" alt="Brand Name"/>
                                        </a>
                                    </div>

                                    <div className="col-6 col-sm-4">
                                        <a href="#" className="brand">
                                            <img src="assets/images/brands/9.png" alt="Brand Name"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {/* <hr className="mt-4 mb-6"/> */}

          {/* <h2 className="title text-center mb-4">Meet Our Team</h2> */}

          {/* <div className="row">
                        <div className="col-md-4">
                            <div className="member member-anim text-center">
                                <figure className="member-media">
                                    <img src="/assets/images/team/member-1.jpg" alt="member"/>

                                    <figcaption className="member-overlay">
                                        <div className="member-overlay-content">
                                            <h3 className="member-title">Samanta Grey<span>Founder & CEO</span></h3>
                                            <p>Sed pretium, ligula sollicitudin viverra, tortor libero sodales leo, eget blandit nunc.</p> 
                                            <div className="social-icons social-icons-simple">
                                                <a href="#/" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                                <a href="#/" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                                <a href="#/" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                                <div className="member-content">
                                    <h3 className="member-title">Samanta Grey<span>Founder & CEO</span></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="member member-anim text-center">
                                <figure className="member-media">
                                    <img src="assets/images/team/member-2.jpg" alt="member photo"/>

                                    <figcaption className="member-overlay">
                                        <div className="member-overlay-content">
                                            <h3 className="member-title">Bruce Sutton<span>Sales & Marketing Manager</span></h3>
                                            <p>Sed pretium, ligula sollicitudin viverra, tortor libero sodales leo, eget blandit nunc.</p> 
                                            <div className="social-icons social-icons-simple">
                                                <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                                <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                                <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                                <div className="member-content">
                                    <h3 className="member-title">Bruce Sutton<span>Sales & Marketing Manager</span></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="member member-anim text-center">
                                <figure className="member-media">
                                    <img src="assets/images/team/member-3.jpg" alt="member photo"/>

                                    <figcaption className="member-overlay">
                                        <div className="member-overlay-content">
                                            <h3 className="member-title">Janet Joy<span>Product Manager</span></h3>
                                            <p>Sed pretium, ligula sollicitudin viverra, tortor libero sodales leo, eget blandit nunc.</p> 
                                            <div className="social-icons social-icons-simple">
                                                <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                                <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                                <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                                <div className="member-content">
                                    <h3 className="member-title">Janet Joy<span>Product Manager</span></h3>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>

        <div className="mb-2" />
      </div>
    </main>
  );
};
