export const TopNav = () => {
    return (
        <div className="header-top">
        <div className="container-fluid">
          <div className="header-left">
            <div className="header-dropdown">
              <a href="#/">Ugx</a>
              <div className="header-menu">
                <ul>
                  <li>
                    <a href="#/">Eur</a>
                  </li>
                  <li>
                    <a href="#/">Usd</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="header-right">
            <ul className="top-menu">
              <li>
                <a href="#/">Links</a>
                <ul>
                  <li>
                    <a href="tel:+256705823830">
                      <i className="icon-phone"></i>Call: +256 705823830
                    </a>
                  </li>
                  {/* <li>
                    <a href="wishlist.html">
                      <i className="icon-heart-o"></i>My Wishlist <span>(0)</span>
                    </a>
                  </li> */}
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  {/* <li>
                    <a href="#signin-modal" data-toggle="modal">
                      <i className="icon-user"></i>Login
                    </a>
                  </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
}