import { useState, useCallback, useEffect } from "react";
import { productApi } from "../../__fake_api__/product-api";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";

export const Product = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const params = useParams();

  const getProducts = useCallback(async () => {
    try {
      const data = await productApi.getProducts();
      setProducts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    const id = params.id;
    if (id) {
      const getProduct = products.find((prod) => prod.id === id);
      setProduct(getProduct);
    }
  }, [params.id, products]);

  return (
    <main className="main">
      <PageHeader title="Product" page="Single" activePage="Product" />
      <div className="page-content">
        <div className="container">
          <div className="product-details-top">
            <div className="row">
              <div className="col-md-3">
                <div className="product-gallery-vertical">
                  <div className="row">
                    {product?.images?.map((prod, index) => (
                      <div class="product-main-image mt-1" key={index}>
                        <img src={prod} alt="product side" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="product-gallery">
                  <div className="row">
                    <figure className="product-main-image">
                      <img
                        id="product-zoom"
                        src={product?.image}
                        data-zoom-image={product?.image}
                        alt="product"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-details">
                  <h1 className="product-title">{product?.name}</h1>

                  <div className="ratings-container" />

                  {/* <div className="product-price">$84.00</div> */}

                  <div className="product-content">
                    <p>{product?.description}</p>
                  </div>

                  {/* <div className="product-details-footer">
                    <div className="product-cat">
                      <span>Color:</span>
                      <a href="#/">Red</a>,<a href="#/">White</a>,
                      <a href="#/">Yellow</a>
                    </div>
                  </div> */}

                  {/* <div className="details-filter-row details-row-size">
                    <label>Size:</label>
                    <div className="select-custom">
                      <select name="size" id="size" className="form-control">
                        <option value="#">various sizes</option>
                        <option value="s">Small</option>
                        <option value="m">Medium</option>
                        <option value="l">Large</option>
                        <option value="xl">Extra Large</option>
                      </select>
                    </div>

                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="product-details-tab">
            <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="product-desc-link"
                  data-toggle="tab"
                  href="#product-desc-tab"
                  role="tab"
                  aria-controls="product-desc-tab"
                  aria-selected="true"
                >
                  Additional information
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  id="product-shipping-link"
                  data-toggle="tab"
                  href="#product-shipping-tab"
                  role="tab"
                  aria-controls="product-shipping-tab"
                  aria-selected="false"
                >
                  Deliveries
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="product-desc-tab"
                role="tabpanel"
                aria-labelledby="product-desc-link"
              >
                <div className="product-desc-content">
                  <h3>Product Information</h3>
                  <p>{product?.description}</p>
                  <p dangerouslySetInnerHTML={product?.additionalInfo} />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="product-shipping-tab"
                role="tabpanel"
                aria-labelledby="product-shipping-link"
              >
                <div className="product-desc-content">
                  <h3>Delivery & returns</h3>
                  <p>
                    We do deliver to clients within Kampala. For full details of
                    the delivery options we offer, please view call us
                    <br />
                    We hope you’ll love every purchase, but if you ever need to
                    return an item you can do so within a month of receipt. For
                    full details of how to make a return.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
