import { CartMenu } from "./CartMenu";
// import { CartIcon } from "./CartIcon";
import { Logo } from "./Logo";
import { NavigationMenu } from "./NavigationMenu";
// import { SearchBar } from "./SearchBar";
import { TopNav } from "./TopNavbar";

export const Header2 = () => {
  return (
    <header className="header header-7">
      <TopNav />
      <div className="header-middle sticky-header">
        <div className="container-fluid">
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>
            <Logo />
            <NavigationMenu />
          </div>
          <div className="header-right">
            {/* <SearchBar /> */}
            <div className="dropdown cart-dropdown">
              {/* <CartIcon /> */}
              <CartMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
