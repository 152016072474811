export const Footer = () => {
    return (
        <footer className="footer footer-2">
        <div className="footer-middle">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-4">
                        <div className="widget widget-about">
                            <img src="/assets/trinity-images/trinity-logo.png" className="footer-logo" alt="Footer Logo" width="200" height="25" />
                            {/* <h2 className="text-white">Trinity Shop</h2> */}
                            <p>
                            Trinity shop is located at atalanta textile centre building, shop AB 31, Nakivubo mews street opposite zibula atudde arcade next to Sekaziga house.
                            </p>
                            
                            <div className="widget-about-info">
                                <div className="row">
                                    <div className="col-sm-6 col-md-4">
                                        <span className="widget-about-title">Got Question? Call us 24/7</span>
                                        <a href="tel:+256785352205">+256 785 352205</a>
                                    </div>
                                    <div className="col-sm-6 col-md-8">
                                        <span className="widget-about-title">Payment Method</span>
                                        {/* <figure className="footer-payments">
                                            <img src="/assets/images/payments.png" alt="Payment methods" width="272" height="20" />
                                        </figure> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-lg-2">
                        <div className="widget">
                            <h4 className="widget-title">Useful links</h4>

                            <ul className="widget-list">
                                <li><a href="#/">About Trinity Shop</a></li>
                                <li><a href="#/">How to shop at Trinity Shop</a></li>
                                <li><a href="#/">FAQ</a></li>
                                <li><a href="#/">Contact us</a></li>
                                {/* <li><a href="#/">Log in</a></li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-4 col-lg-2">
                        <div className="widget">
                            <h4 className="widget-title">Customer Service</h4>

                            <ul className="widget-list">
                                {/* <li><a href="#/">Payment Methods</a></li> */}
                                <li><a href="#/">Money-back guarantee!</a></li>
                                {/* <li><a href="#/">Returns</a></li> */}
                                {/* <li><a href="#/">Shipping</a></li> */}
                                <li><a href="#/">Terms and conditions</a></li>
                                <li><a href="#/">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-4 col-lg-2">
                        <div className="widget">
                            <h4 className="widget-title">My Account</h4>

                            <ul className="widget-list">
                                <li><a href="#/">Sign In</a></li>
                                {/* <li><a href="/cart">View Cart</a></li> */}
                                {/* <li><a href="#/">My Wishlist</a></li> */}
                                {/* <li><a href="#/">Track My Order</a></li> */}
                                <li><a href="#/">Help</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <div className="widget widget-newsletter">
                            <h4 className="widget-title">Sign up to newsletter</h4>

                            <p>Signup to our newsletter to get the recent updates.</p>
                            
                            <form action="#">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required />
                                    <div className="input-group-append">
                                        <button className="btn btn-dark" type="submit"><i className="icon-long-arrow-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-bottom">
            <div className="container-fluid">
                <p className="footer-copyright">Copyright © 2024 Trinity Shop. All Rights Reserved.</p>
                <ul className="footer-menu">
                    <li><a href="#/">Terms Of Use</a></li>
                    <li><a href="#/">Privacy Policy</a></li>
                </ul>

                <div className="social-icons social-icons-color">
                    <span className="social-label">Social Media</span>
                    <a href="#/" className="social-icon social-facebook" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                    <a href="#/" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                    <a href="https://www.instagram.com/trinity.shop2023?igsh=aWNoeGg1c3djODNv" className="social-icon social-instagram" title="Instagram" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a>
                    <a href="#/" className="social-icon social-youtube" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                   
                </div>
            </div>
        </div>
    </footer>
    )
}