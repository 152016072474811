import { Banner } from "../../components/mainPage/Banner";
import { BannerGroup } from "../../components/mainPage/BannerGroup";
// import { BannerSet } from "../../components/mainPage/BannerSet";
// import { BannerVideo } from "../../components/mainPage/BannerVideo";
// import { Cta } from "../../components/mainPage/Cta";
import { NewArrivals } from "../../components/mainPage/NewArrivals";
import { TrendyProducts } from "../../components/mainPage/TrendyProducts";
import { TrinityBrands } from "../../components/mainPage/TrinityBrands";
import { productApi } from "../../__fake_api__/product-api";
import { useCallback,useState, useEffect } from "react";

export const Home = () => {
  const  [products, setProducts] = useState([])
 
  const getProducts = useCallback(async () => {
    try {
      const data = await productApi.getProducts();
      setProducts(data)
    } catch (error) {
      console.error(error);
    }
  },[])

  useEffect(() => {
    getProducts()
  },[getProducts])

  return (
    <main className="main">
      <Banner />
      <TrinityBrands/>
      <BannerGroup />
      <TrendyProducts products={products} />
      {/* <BannerVideo /> */}
      {/* <BannerSet /> */}
      <NewArrivals products={products} />
      {/* <Cta /> */}
    </main>
  );
};
