import { PageHeader } from "../../components/PageHeader";
import { ShopProduct } from "../../components/Shop/ShopProduct";
import { SidebarFilter } from "../../components/Shop/SidebarFilter";
import { ToolBox } from "../../components/Shop/ToolBox";
import { productApi } from "../../__fake_api__/product-api";
import { useCallback, useState, useEffect } from "react";

const ProductContainer = ({ children }) => {
  return (
    <div className="products">
      <div className="row">{children}</div>
    </div>
  );
};

const LoadMore = (props) => {
  return (
    <div className="load-more-container text-center">
      <a href="#/" className="btn btn-outline-darker btn-load-more">
        More Products <i className="icon-refresh"></i>
      </a>
    </div>
  );
};

export const Shop = () => {
  const [products, setProducts] = useState([]);
  const getProducts = useCallback(async () => {
    try {
      const data = await productApi.getProducts();
      setProducts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);
  return (
    <main className="main">
      <PageHeader title="Products" page="Shop" activePage="Products" />
      <div className="page-content ">
        <div className="container-fluid">
          <ToolBox products={products} />
          <ProductContainer>
            {products.map((product) => (
              <ShopProduct product={product} />
            ))}
            {/* <ShopProduct /> */}
          </ProductContainer>
        </div>
        <LoadMore />
        <SidebarFilter />
      </div>
    </main>
  );
};
