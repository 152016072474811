export const NavigationMenu = () => {
  const path = window.location.pathname;
  return (
    <nav className="main-nav">
      <ul className="menu sf-arrows">
        <li className={`megamenu-container ${path === "/" ? "active" : null}`}>
          <a href="/" className="">
            Home
          </a>
        </li>
        <li className={`${path === "/shop" ? "active" : null}`}>
          <a href="/shop">Shop</a>
        </li>
        <li className={`${path === "/about" ? "active" : null}`}>
          <a href="/about">About Us</a>
        </li>
        <li className={`${path === "/contact" ? "active" : null}`}>
          <a href="/contact">Contact Us</a>
        </li>
      </ul>
    </nav>
  );
};
