import { Outlet } from "react-router-dom";
import { SigninSignupModal } from "../components/authentication/SigninSignupModal";
import { Footer } from "../components/Footer/Footer";
import { Header2 } from "../components/Header/Header2";
import { MobileMenu } from "../components/MobileMenu/MobileMenu";
// import { NewsletterModal } from "../components/Newsletter/NewsletterModal";

export const OtherLayout = () => {
  return (
    <>
    <div className="page-wrapper">
      <Header2/>
      <Outlet />
      <Footer />
    </div>
    <button id="scroll-top" title="Back to Top"><i className="icon-arrow-up"></i></button>
    <MobileMenu />
    {/* <NewsletterModal /> */}
    <SigninSignupModal />
    </>
  );
};
