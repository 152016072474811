import { useRoutes } from "react-router-dom";
import { MainLayout } from "./layouts/MainLayout";
import { Home } from "./pages/Home/Home";
import { Shop } from "./pages/Shop/Shop";
import { Product } from "./pages/Shop/Product";
import { OtherLayout } from "./layouts/OtherLayout";
import { AboutUs } from "./pages/about/AboutUs";
import { ContactUs } from "./pages/contact-us/ContactUs";

const Router = () => {
  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
      ],
    },
    {
      element: <OtherLayout />,
      children: [
        {
          path: "/shop",
          element: <Shop />,
        },
        {
          path: "/product/:id",
          element: <Product />,
        },
        {
          path: "/about",
          element: <AboutUs />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
      ],
    },
  ]);
};

export default Router;
