import { Outlet } from "react-router-dom";
import { SigninSignupModal } from "../components/authentication/SigninSignupModal";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { MobileMenu } from "../components/MobileMenu/MobileMenu";
// import { NewsletterModal } from "../components/Newsletter/NewsletterModal";

export const MainLayout = () => {
  return (
    <>
    <div className="page-wrapper">
      <Header/>
      <Outlet />
      <Footer />
    </div>
    <button id="scroll-top" title="Back to Top"><i className="icon-arrow-up"></i></button>
    <MobileMenu />
    {/* <NewsletterModal /> */}
    <SigninSignupModal />
    </>
  );
};
