export const ShopProduct = (props) => {
  const { product } = props;
  return (
    <div
      className="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2"
      key={product.id}
    >
      <div className="product">
        <figure className="product-media">
          {/* <span className="product-label label-new">New</span> */}
          <a href={`/product/${product.id}`}>
            <img
              src={product.image}
              alt="Productimage"
              className="product-image"
            />
          </a>

          <div className="product-action-vertical">
            
          </div>

        </figure>
        <div className="product-body">
          <div className="product-cat">
            {/* <a href="#/">{product.category}</a> */}
          </div>
          <h3 className="product-title">
            <a href={`/product/${product.id}`}>{product.name}</a>
          </h3>

          {product.attributes ? (
            <div className="product-nav product-nav-dots">
              {product.attributes.map((attribute) => (
                <a
                  href="#/"
                  className=""
                  style={{ background: `${attribute}` }}
                >
                  <span className="sr-only">Color name</span>
                </a>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
